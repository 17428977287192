var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        lazy: "",
        "no-fade": "",
        scrollable: "",
        centered: "",
        size: "md",
        title: "Upload de CRLV Digital",
        "ok-disabled": _vm.loading,
        "cancel-disabled": _vm.loading,
        "ok-title": "Enviar",
        "cancel-title": "Cancelar",
      },
      on: { ok: _vm.uploadFile, hidden: _vm.cleanModal },
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("h1", [_c("i", { staticClass: "fa fa-spin fa-refresh" })]),
            _c("p", [_vm._v("Enviando Documentos...")]),
          ])
        : _c(
            "div",
            [
              _c("b-form-file", {
                attrs: {
                  "file-name-formatter": _vm.formatNames,
                  placeholder: "Escolha um arquivo, ou arraste ele aqui...",
                  "drop-placeholder": "Arraste o arquivo aqui...",
                  "browse-text": "Buscar",
                  accept: _vm.fileMediaType,
                  multiple: _vm.allowMultipleFiles,
                },
                model: {
                  value: _vm.file,
                  callback: function ($$v) {
                    _vm.file = $$v
                  },
                  expression: "file",
                },
              }),
              Boolean(_vm.file) && !Array.isArray(_vm.file)
                ? _c("div", { staticClass: "mt-3" }, [
                    _vm._v(
                      " Arquivo Selecionado: " +
                        _vm._s(_vm.file ? _vm.file.name : "") +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.allowMultipleFiles
                ? _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-list-group",
                        _vm._l(_vm.file, function (f, index) {
                          return _c(
                            "b-list-group-item",
                            { key: Math.random() + index },
                            [_vm._v(" " + _vm._s(f.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }