import apollo from '@utils/graphql';
import GetPreSignedUrl from '@graphql/pre-signed/queries/get.gql';

export const types = {
  CLRV_DIGITAL_ZIPPED: 'CLRV_DIGITAL_ZIPPED',
  CLRV_DIGITAL: 'CLRV_DIGITAL',
};

export const toBase64 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
};

export const preSignedUrl = async (variables) => apollo.query({
  query: GetPreSignedUrl,
  variables,
});

export const uploadToAws = async (url, file, contentType = 'application/pdf') => {
  try {
    await fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': contentType },
      body: file,
     });

    return true;
    } catch (e) {
     return false;
  }
};

export const uploadFile = async (file, type, contentType) => {
  if (!types.hasOwnProperty(type)) throw new Error('File type not found');
  try {
    const filename = file.name;

    const preSignedVariables = {
      input: {
        filename,
        type,
      }
    };

    try {
      const { data: { getPreSignedUrl: { url } } } = await preSignedUrl(preSignedVariables);

      if (!url) throw new Error('Empty url');

      const successAws = await uploadToAws(url, file, contentType);

      return {
        success: successAws
      }
    } catch (e) {
      throw new Error(`Presigned url error: ${e}`);
    }
  } catch (e) {
    throw e;
  }
};
