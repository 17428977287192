var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h2", [_vm._v(" Frota ")]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { cols: "6" } },
            [
              _vm.isBrazil
                ? _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.digitalDocumentModal.show()
                        },
                      },
                    },
                    [_vm._v(" Atualizar CRLV Digital ")]
                  )
                : _vm._e(),
              _c("b-button", { attrs: { type: "button", to: "/providers" } }, [
                _vm._v(" Fornecedores "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("fleet-list-table", { ref: "fleetListTable" }),
      _c("digital-document-modal", { ref: "digitalDocumentModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }