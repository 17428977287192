<template>
  <Layout>
    <b-row class="pb-4">
      <b-col cols="6">
        <h2>
          Frota
        </h2>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button
          v-if="isBrazil"
          type="button"
          class="mr-2"
          @click="$refs.digitalDocumentModal.show()"
        >
          Atualizar CRLV Digital
        </b-button>
        <b-button type="button" to="/providers">
          Fornecedores
        </b-button>
      </b-col>
    </b-row>
    <fleet-list-table ref="fleetListTable" />
    <digital-document-modal
      ref="digitalDocumentModal"
    />
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import FleetListTable from '@components/fleet/fleet-list-table';
import { isBrazil } from '@utils/helper-region';
import DigitalDocumentModal from '@components/modals/DocsUpload/digital-document';

export default {
  name: 'Fleet',
  page: {
    title: 'Frota',
  },
  components: {
    DigitalDocumentModal,
    Layout,
    FleetListTable,
  },
  computed: {
    isBrazil,
  },
};
</script>

<style lang="scss" scoped>
  .maps {
    border-radius: 0.25rem;
    height: 350px;
  }
</style>
