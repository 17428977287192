var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fleet-list-table" },
    [
      _c("custom-data-table", {
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.car_list_query,
          "fixed-filters": { status: [] },
          "query-node": "cars",
        },
        on: { "vuetable:row-clicked": _vm.$_goTo },
        scopedSlots: _vm._u([
          {
            key: "license_plate",
            fn: function (props) {
              return _c("span", {}, [
                _c("strong", [_vm._v(_vm._s(props.rowData.license_plate))]),
                _c("br"),
                _c("span", { staticClass: "small text-muted" }, [
                  props.rowData.brand && props.rowData.model
                    ? _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(props.rowData.brand.name) +
                            " / " +
                            _vm._s(props.rowData.model.name) +
                            " "
                        ),
                      ])
                    : _c("strong", [_vm._v(" Processando... ")]),
                ]),
              ])
            },
          },
          {
            key: "category",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(_vm.translateCategoryEnum(props.rowData.category)) +
                    " "
                ),
              ])
            },
          },
          {
            key: "place",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(_vm._s(props.rowData.place.address_state)),
              ])
            },
          },
          {
            key: "status",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  props.rowData.booking
                    ? [
                        _c("strong", [_vm._v("Alugado")]),
                        _c("br"),
                        props.rowData.booking.driver
                          ? _c("span", { staticClass: "small text-muted" }, [
                              _vm._v(_vm._s(props.rowData.booking.driver.name)),
                            ])
                          : _c("span", { staticClass: "small text-muted" }, [
                              _vm._v("Motorista não cadastrado"),
                            ]),
                      ]
                    : [
                        _c("strong", { staticClass: "text-muted" }, [
                          _vm._v("Pátio"),
                        ]),
                        _c("br"),
                        props.rowData.place
                          ? _c("span", { staticClass: "small text-muted" }, [
                              _vm._v(_vm._s(props.rowData.place.name)),
                            ])
                          : _c("span", { staticClass: "small text-muted" }, [
                              _vm._v("Sem pátio definido"),
                            ]),
                      ],
                ],
                2
              )
            },
          },
          {
            key: "status_operation",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-badge",
                    { attrs: { variant: props.rowData.status_operation } },
                    [
                      _vm._v(
                        _vm._s(
                          props.rowData.status_operation
                            ? props.rowData.status_operation
                            : "--"
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "edit",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-button",
                    {
                      attrs: { title: "Editar carro", variant: "link" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$_goToCarEdit(props.rowData.id)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }