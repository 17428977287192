<template>
  <div class="fleet-list-table">
    <custom-data-table
      :fields="fields"
      :filters="filters"
      :query="car_list_query"
      :fixed-filters="{ status: [] }"
      query-node="cars"
      @vuetable:row-clicked="$_goTo"
    >
      <span
        slot="license_plate"
        slot-scope="props"
      >
        <strong>{{ props.rowData.license_plate }}</strong>
        <br>
        <span class="small text-muted">
          <strong v-if="props.rowData.brand && props.rowData.model">
            {{ props.rowData.brand.name }} / {{ props.rowData.model.name }}
          </strong>
          <strong v-else>
            Processando...
          </strong>
        </span>
      </span>
      <span
        slot="category"
        slot-scope="props"
      >
        {{ translateCategoryEnum(props.rowData.category) }}
      </span>
      <span slot="place" slot-scope="props">{{ props.rowData.place.address_state }}</span>
      <span
        slot="status"
        slot-scope="props"
      >
        <template
          v-if="props.rowData.booking"
        >
          <strong>Alugado</strong>
          <br>
          <span v-if="props.rowData.booking.driver" class="small text-muted">{{ props.rowData.booking.driver.name }}</span>
          <span v-else class="small text-muted">Motorista não cadastrado</span>
        </template>
        <template
          v-else
          class="text-muted"
        >
          <strong class="text-muted">Pátio</strong>
          <br>
          <span v-if="props.rowData.place" class="small text-muted">{{ props.rowData.place.name }}</span>
          <span v-else class="small text-muted">Sem pátio definido</span>
        </template>
      </span>

      <span slot="status_operation" slot-scope="props">
        <b-badge
          :variant="props.rowData.status_operation"
        >{{ props.rowData.status_operation ? props.rowData.status_operation : '--' }}</b-badge>
      </span>
      <span
        slot="edit"
        slot-scope="props"
      >
        <b-button
          title="Editar carro"
          variant="link"
          @click.stop="$_goToCarEdit(props.rowData.id)"
        >
          <i class="fa fa-edit" />
        </b-button>
      </span>
    </custom-data-table>
  </div>
</template>

<script>
import CustomDataTable from '@components/CustomDataTable';
import FLEET_LIST from '@graphql/car/queries/fleet-list.gql';
import { enumStatusOperation, enumCarsCategory } from '@utils/car';

export default {
  components: {
    CustomDataTable,
  },
  data() {
    return {
      current_item: {
        id: '0',
      },
      car_list_query: FLEET_LIST,
      fields: [
        {
          name: 'license_plate',
          title: 'Placa',
          sortField: 'license_plate',
          width: '15%',
        },
        {
          name: 'category',
          title: 'Categoria',
          sortField: 'category',
          width: '15%',
        },
        {
          name: 'place',
          title: 'Local',
          sortField: 'place',
          width: '10%',
        },
        {
          name: 'status',
          title: 'Status',
          width: '40%',
        },
        {
          name: 'status_operation',
          title: '',
          width: '9%',
        },
        {
          name: 'edit',
          title: '',
          width: '1%',
        }
      ],
    };
  },
  computed: {
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: 'Buscar por placa',
            placeholder: 'Ex: MGO3646',
          },
          graphqlQuery(value) {
            return {
              $or: [
                {
                  license_plate: {
                    $like: `%${value}%`,
                  },
                },
              ],
            };
          }
        },
        category: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Categoria',
            options: [
              {
                value: null,
                text: 'Todos',
              },
              ...enumCarsCategory,
            ],
          },
          graphqlQuery(value) {
            if (value) return { category: [value] };
          },
        },
        status_operation: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Status',
            options: [
              {
                value: null,
                text: 'Todos',
              },
              ...enumStatusOperation,
            ],
          },
          graphqlQuery(value) {
            return !value
              ? {
                  status_operation: enumStatusOperation.map(item => item.value),
                }
              : { status_operation: [value] };
          },
        },
      };
    },
  },
  methods: {
    translateCategoryEnum(category){
      return enumCarsCategory.find((c) => c.value === category)?.text || category || '-';
    },
    $_goTo(_item = null) {
      _item = _item.data;
      this.$router.push(`/fleet/${_item.id}`);
    },
    $_goToCarEdit(id) {
      this.$router.push(`/fleet/${id}/edit`);
    }
  },
};
</script>

<style lang="sass" scoped>
.vuetable-th-slot-license_plate
  width: 250px
.vuetable-th-slot-edit
  width: 30px
.vuetable-th-slot-status_operation
  width: 120px
.edit-button
  padding: 0
  margin: 0
.badge
  padding: 5px 10px
  color: white
  &-LOCKED
    background: red
  &-LOCK_PENDING,
  &-UNLOCK_PENDING
    background: orange
  &-UNLOCKED
    background: green
</style>
