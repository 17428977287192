<template>
  <b-modal
    ref="modal"
    lazy
    no-fade
    scrollable
    centered
    size="md"
    title="Upload de CRLV Digital"
    :ok-disabled="loading"
    :cancel-disabled="loading"
    ok-title="Enviar"
    cancel-title="Cancelar"
    @ok="uploadFile"
    @hidden="cleanModal"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <h1><i class="fa fa-spin fa-refresh"/></h1>
      <p>Enviando Documentos...</p>
    </div>
    <div v-else>
      <b-form-file
        v-model="file"
        :file-name-formatter="formatNames"
        placeholder="Escolha um arquivo, ou arraste ele aqui..."
        drop-placeholder="Arraste o arquivo aqui..."
        browse-text="Buscar"
        :accept="fileMediaType"
        :multiple="allowMultipleFiles"
      ></b-form-file>
      <div
        v-if="Boolean(file) && !Array.isArray(file)"
        class="mt-3">
        Arquivo Selecionado: {{ file ? file.name : '' }}
      </div>
      <div
        class="mt-3"
        v-if="allowMultipleFiles"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(f, index) in file"
            :key="Math.random()+index"
          >
            {{ f.name }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { uploadFile as uploadFileUtil } from '@utils/upload';

export default {
  name: 'DigitalDocumentModal',
  data: () => ({
    file: null,
    loading: false,
    allowMultipleFiles: false,
    fileMediaType: 'application/zip',
  }),
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name
      } else {
        return `${files.length} arquivos selecionados`
      }
    },
    showMsg (type, text) {
      this.$swal({
        type,
        text,
      });
    },
    cleanModal () {
      this.file = null;
    },
    async uploadFile(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$log.logMethod('uploadFile');

      try {
        this.loading = true;

        const uploadResult = await uploadFileUtil(this.file, 'CLRV_DIGITAL_ZIPPED', this.fileMediaType);

        if (uploadResult.success) {
          this.showMsg('success', 'Arquivo enviado com sucesso!');
          this.cleanModal();
          this.hide();
        } else {
          this.showMsg('error', 'Não foi possível enviar o arquivo, tente novamente!');
        }
      } catch (e) {
        this.$log.logError(e);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    file(newFile) {
      if(newFile && newFile.type !== this.fileMediaType) {
        const self = this;
        this.$nextTick(() => self.cleanModal());
      }
    }
  }
}
</script>
